<!--
 * @Author: your name
 * @Date: 2021-11-24 20:57:18
 * @LastEditTime: 2023-02-10 15:31:55
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 首页 合作伙伴
 * @FilePath: \new-website-1123\src\views\Home\partner\index.vue
-->
<template>
  <div class="home-partner">
    <div class="partner-content pt80">
      <div class="title-div" :class="{ views: Untitled == 'false' }">
        <!-- <span> 合作伙伴 </span> -->
        <h-title :titleimg="titleimg" :stitleimg="stitleimg"></h-title>
      </div>
      <div
        class="content-div"
        v-if="Gtitem == 1"
        :class="{ views: Untitled == 'false' }"
      >
        <div class="top-div">
          <swiper ref="mySwiper1" :options="swiperOptions" class="swiper">
            <swiper-slide v-for="item in logoArr" :key="item.id">
              <img class="data-img" :src="item.cover" alt="" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="bottom-div">
          <swiper ref="mySwiper2" :options="swiper2Options" class="swiper">
            <swiper-slide v-for="item in logoArr" :key="item.id">
              <img class="data-img" :src="item.cover" alt="" />
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="content-div" v-else :class="{ views: Untitled == 'false' }">
        <div class="top-div">
          <swiper ref="mySwiper1" :options="swiperOptions2" class="swiper">
            <swiper-slide v-for="item in logoArr" :key="item.id">
              <img class="data-img" :src="item.cover" alt="" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="bottom-div">
          <swiper ref="mySwiper2" :options="swiper2Options2" class="swiper">
            <swiper-slide v-for="item in logoArr" :key="item.id">
              <img class="data-img" :src="item.cover" alt="" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <st-footer></st-footer>
  </div>
</template>

<script>
import stFooter from "@/components/stFooter.vue";
import hTitle from "../components/title.vue";
export default {
  name: "home-partner",
  props: {
    Untitled: {
      type: String,
      default: "",
    },
  },
  components: {
    stFooter,
    hTitle,
  },
  data() {
    return {
      titleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle6.png",
      stitleimg: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/whitetitle6s.png",
      logoArr: [
        {
          id: 1,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo1.jpg",
        },
        {
          id: 2,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo2.jpg",
        },
        {
          id: 3,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo3.jpg",
        },
        {
          id: 4,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo4.jpg",
        },
        {
          id: 5,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo5.jpg",
        },
        {
          id: 6,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo6.jpg",
        },
        {
          id: 7,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo7.jpg",
        },
        {
          id: 8,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo8.jpg",
        },
        {
          id: 9,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo9.jpg",
        },
        {
          id: 10,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo10.jpg",
        },
        {
          id: 11,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo11.jpg",
        },
        {
          id: 12,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo12.png",
        },
        {
          id: 13,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo13.png",
        },
        {
          id: 14,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo14.png",
        },
        {
          id: 15,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo15.png",
        },
        {
          id: 16,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo16.png",
        },
        {
          id: 17,
          cover:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/partner-logo17.png",
        },
      ],
      Gtitem: 1,
      swiper2Options: {
        speed: 17 * 1000, //匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          reverseDirection: true,
        },
        loop: true,
        mousewheel: false,
        slidesPerView: 6,
        freeMode: true,
      },
      swiperOptions: {
        speed: 17 * 1000, //匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        mousewheel: false,
        slidesPerView: 6,
        freeMode: true,
      },
      swiper2Options2: {
        speed: 17 * 1000, //匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          reverseDirection: true,
        },
        loop: true,
        mousewheel: false,
        slidesPerView: 4,
        freeMode: true,
      },
      swiperOptions2: {
        speed: 17 * 1000, //匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        mousewheel: false,
        slidesPerView: 4,
        freeMode: true,
      },
    };
  },
  created() {
    if (window.innerWidth < 800) {
      this.Gtitem = 2;
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-partner {
  height: 100vh;
  width: 100%;
  .partner-content {
    height: calc(100% - 402px);

    .title-div {
      padding-left: 11vw;
      padding-top: 30px;
      &.views {
        display: none;
      }
    }
    .data-img {
      width: 12vw;
      height: 6vw;
      object-fit: cover;
    }
  }
  .content-div {
    padding-top: 30px;
    &.views {
      padding-top: 0;
    }
    .top-div {
      margin-top: 20px;
    }
    .bottom-div {
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: 1500px) {
  .home-partner {
    .partner-content {
      height: calc(100% - 402px);
    }
  }
}
@media screen and (max-width: 1500px) {
  .home-partner {
    .partner-content {
      height: calc(100% - 300px);
    }
  }
}
@media screen and (max-height: 800px) {
  .home-partner {
    .partner-content {
      height: calc(100% - 300px);
      .data-img {
        width: 13vw;
        height: 6.2vw;
        object-fit: cover;
      }
    }
    .content-div {
      padding-top: 30px;
      .top-div {
        margin-top: 0;
      }
      .bottom-div {
        margin-top: 20px;
      }
    }
  }
}
@media only screen and (max-width: $mobile-width) {
  .home-partner {
    .partner-content {
      height: auto;
      padding-top: 1.2rem;
      padding-bottom: 0.84rem;
      .data-img {
        width: calc(25vw - 0.18rem);
        height: 0.78rem;
        object-fit: cover;
      }
      .title-div {
        padding-left: 0;
        font-size: 0.53rem;
        text-align: center;
      }
    }
    .content-div {
      .bottom-div {
        margin-top: 0.18rem;
      }
    }
  }
}
</style>
