<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2023-02-10 15:25:36
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 关于上太
 * @FilePath: \new-website-1123\src\views\about\profile\index.vue
-->
<template>
  <div class="about-profile">
    <div class="banner-box">
      <h-banner :url="banner">
        <div class="banner-content-div">
          <span class="title-span">关于上太</span>
          <span class="desc-span"
            >上太科技专注于搭建精准高效的智慧农业物联网平台，全面整合智慧农业解决方案，远程联动控制各类农业自动化设备；</span
          >
          <span class="desc-span"
            >为用户提供智能化、精准化管理决策，构建无人化智慧农业生态，推动全球农业的革新和发展。</span
          >
        </div>
      </h-banner>
    </div>
    <!-- 上太简介 -->
    <div class="about-brief opacity-page scale-page outer_clear" style="display: flex;align-items: center;" id="miaost1">
      <div class="about-brief-left">
        <div class="about-brief-connect">
          <h2>上太<span>简介</span></h2>
          <p class="about-brief-text">
            四川上太科技有限公司是一家提供智慧农业服务的科技型企业。
          </p>
          <p class="about-brief-text">
            上太深耕科技创新，融合时代发展，赋能农业；以提质增效，惠民惠农为核心，建立多个智慧农业生态布局；始终以科技为支撑，助力农业产业化转型升级。
          </p>
          <p class="about-brief-text">
            依托集团核心科技创新技术，上太科技公司采用集农业自动化产品设备研发、生产、销售、服务为一体的全产业链经营模式，提供一站式智慧农业物联网软硬件服务。
          </p>

          <ul class="about-brief-column">
            <li>
              <span class="iconfont icongexin"></span>
              <span>以科技革新为契机</span>
            </li>
            <li>
              <span class="iconfont iconfuwu"></span>
              <span>以科技服务为支撑</span>
            </li>
            <li>
              <span class="iconfont iconronghe"></span>
              <span>以科技融合为发展</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="about-brief-video animation-3">
        <div class="about-brief-videoimg" @click="play()" id="poster">
          <i class="iconfont iconbofang"></i>
        </div>
        <video
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/viedo/propaganda.mp4"
          id="video"
        >
          您的浏览器不支持video播放视频
        </video>
      </div>
    </div>
    <!-- 使命 -->
    <div class="profile-mission opacity-page scale-page" id="miaost2">
      <div class="profile-mission-connect">
        <div
          class="profile-mission-modular"
          v-for="(item, index) in mission"
          :key="index"
        >
          <img class="animation-3" :src="item.image" />
          <h2 class="animation-3">{{ item.label }}</h2>
          <div class="label_text animation-3">{{ item.label_text }}</div>
        </div>
      </div>
    </div>
    <!--目标-->
    <div></div>
    <div class="profile-target public-spacing-tb opacity-page scale-page">
      <div class="profile-target-connect">
        <div>
          <h2>我们的<span>建设目标</span></h2>
          <h4 class="profile-target-title">
            建设天空地一体，覆盖耕、种、管、收、售全流程的，能全方位立体感知、辅助生产、有专家帮扶的、市场运作的、
            提供质量溯源的，智慧化的数字农业生产平台。
          </h4>

          <ul class="profile-target-ul">
            <li v-for="(item, index) in target" :key="index">
              <img class="animation-3" :src="item.image" />
              <div class="profile-target-ul-connect animation-3">
                <h4>{{ item.label }}</h4>
                <p>{{ item.label_text }}</p>
              </div>

              <div class="profile-target-ul-location animation-3">
                <div>
                  <h4>{{ item.label }}</h4>
                  <p>{{ item.label_text }}</p>
                </div>
              </div>
            </li>
          </ul>

          <div class="home-media-moile">
            <swiper
              class="right-divmap swiper"
              ref="swiper"
              :options="swiperOptionst2"
              @slideChange="slideChange"
            >
              <swiper-slide v-for="item in target" :key="item.key">
                <slot :row="item">
                  <div class="right-divmap-connect">
                    <img
                      class="data-img"
                      :src="item.image"
                      alt=""
                      :style="{
                        'object-fit': fit,
                      }"
                    />
                    <div class="views">
                      <h2>{{ item.label }}</h2>
                      <h4
                        style="
                          display: -webkit-box;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          word-wrap: break-word;
                          white-space: normal;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                          line-height: 0.35rem;
                          height: 0.87rem;
                          overflow: hidden;
                        "
                      >
                        {{ item.label_text }}
                      </h4>
                    </div>
                  </div>

                  <div class="filter-div"></div>
                </slot>
              </swiper-slide>
              <div class="home-pagination2" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>

    <!--建设内容-->
    <div class="profile-target public-spacing-tb opacity-page scale-pag">
      <div class="profile-target-connect">
        <div>
          <h2>总体建设<span>内容</span></h2>
          <h4 class="profile-target-title">
            覆盖农业生产全链路的耕种管收售360°全方位保障系统。打造以天、空、地为维度的耕、种、管、收、售。
          </h4>
          <img
            src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/construction-img.png"
            class="img-width"
          />
        </div>
      </div>
    </div>

    <!-- 业务范围 -->
    <!-- <div class="public-spacing-tb">
      <w-title
        model="业务"
        modelkey="范围"
        paragraph="上太产品与服务遍及全国，联合所有合作伙伴，共建可持续发展未来"
      ></w-title>
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/map.png"
        class="businessmap"
      />
    </div> -->

    <!-- 设备 -->
    <w-title model="合作" modelkey="伙伴"></w-title>
    <hParther Untitled="false"></hParther>
  </div>
</template>

<script>
import hBanner from "../components/banner.vue";
import hParther from "../../Home/partner";
import wTitle from "@/components/sTitle/viewtitle";
export default {
  name: "about-profile",
  components: {
    hBanner,
    wTitle,
    hParther,
  },
  data() {
    let w = window.innerWidth > 800 ? 5 : 1;
    return {
      swiperOptionst2: {
        pagination: {
          el: ".home-media-moile .home-pagination2",
          clickable: true,
        },
        autoplay: true,
        loop: true,
        speed: 800,
        mousewheel: false,
        slidesPerGroup: 1,
        loopedSlides: w,
        spaceBetween: 20,
      },
      banner:'https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/banner.png',
      mission: [
        {
          label: "理念",
          label_text: "上善惠农  太平兴中",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/mission-modular-1.png",
        },
        {
          label: "使命",
          label_text: "以科技服务农业  让服务升级体验",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/mission-modular-2.png",
        },
        {
          label: "愿景",
          label_text: "成为顶尖的绿色智慧农业综合服务商",
          image:"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/mission-modular-3.png",
        },
      ],

      target: [
        {
          label: "立体感知",
          label_text:
            "通过5G/4G智慧化物联传感设备，实现生产种植环的全面感知、数据精准采集。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/profile-target-1.png",
        },
        {
          label: "辅助生产",
          label_text:
            "通过大数据服务，建立农业生产模型，为农户提供超前的预测预报服务，生产管理建议等服务。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/profile-target-2.png",
        },
        {
          label: "资深帮扶",
          label_text:
            "打造多平台共享专家服务系统，建立专业的农业专家咨询服务平台，帮助指导解决生产、管理难题，为农户提供多对一的专业帮扶。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/profile-target-3.png",
        },
        {
          label: "市场流通",
          label_text:
            "借助人工智能技术与电子商务技术，为农户提供产量预测、超前订单预定，出库销售辅助等服务，实现农产品的供销配送一体化，引导农业市场化运作。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/profile-target-4.png",
        },
        {
          label: "质量溯源",
          label_text:
            "帮助实现从选种、耕种、管理、收获全流程的质量安全溯源服务，作物健康有保障，责任很明确。",
          image: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/about/profile/profile-target-5.png",
        },
      ],
    };
  },
  created() {},
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {
    goAnchor(selector) {
      let anchor = this.$el.querySelector(selector);
      document.documentElement.scrollTop = anchor.offsetTop
    },

    play() {
      var video = document.getElementById("video");
      document.getElementById("poster").style.display = "none";
      document.getElementById("miaost1").classList.add("videoscreen");
      video.play();
    },
    slideChange() {
      this.$emit("change", this.$refs.swiper.$swiper.realIndex);
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.goAnchor(window.location.hash);
    });
  },
  watch: {
    $route() {
      this.goAnchor(window.location.hash);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-partner {
  height: auto;
  ::v-deep .pt80 {
    padding-bottom: 80px;
    padding-top: 0px;
  }
}
.banner-box {
  overflow: hidden;
  // height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  .banner-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-text;
    padding-bottom: 200px;
    .title-span {
      font-size: 54px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .desc-span {
      font-size: 22px;
      font-weight: 400;
      line-height: 40px;
    }

    @media screen and (max-width: 1400px) {
      .title-span {
        font-size: 44px;
      }
      .desc-span {
        font-size: 18px;
      }
    }
  }
}

.home-media-moile {
  display: none;
}

.about-brief {
  height: 100vh;
  color: #fff;
  &.videoscreen {
    .about-brief-left {
      display: none;
    }
    .about-brief-video {
      width: 100%;
    }
  }
  .about-brief-left {
    width: 50%;
    float: left;
    .about-brief-connect {
      width: 690px;
      width: 71.8%;
      float: right;
      text-align: left;
      // padding-top: 136px;
      margin-right: 60px;
      h2 {
        font-size: 60px;
        margin-bottom: 56px;
        span {
          color: $color-active;
        }
      }
      ul.about-brief-column {
        padding-top: 68px;
        li {
          font-size: 36px;
          line-height: 72px;
          color: $color-active;
          .iconfont {
            font-size: 40px;
            margin-right: 28px;
          }
        }
      }
      .about-brief-text {
        font-size: 22px;
        line-height: 36px;
        margin-top: 18px;
      }
    }
  }
  .about-brief-video {
    width: 50%;
    overflow: hidden;
    // height: 100%;
    margin:0 auto;
    float: right;
    position: relative;
    .about-brief-videoimg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      line-height: 100vh;
      z-index: 888;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont {
        font-size: 88px;
        cursor: pointer;
      }
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.businessmap {
  display: block;
  margin: 0 auto;
  width: 1136px;
}
.profile-mission {
  height: 100vh;
  position: relative;
  .profile-mission-connect {
    width: 78.125%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .profile-mission-modular {
      height: 240px;
      margin-bottom: 30px;
      font-size: 60px;
      overflow: hidden;
      position: relative;
      &:hover .label_text {
        opacity: 1;
      }
      &:hover h2 {
        opacity: 0;
      }
      &:hover img {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      h2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        color: $color-text;
        font-size: 60px;
        line-height: 240px;
        letter-spacing: 10px;
        opacity: 1;
      }
      .label_text {
        position: absolute;
        left: 0;
        right: 0;
        color: $color-text;
        top: 0;
        bottom: 0;
        z-index: 8;
        line-height: 240px;
        text-align: center;
        font-size: 36px;
        opacity: 0;
        background: rgba(22, 22, 22, 0.8);
      }
    }
    .profile-mission-modular:last-child {
      margin-bottom: 0;
    }
  }
}
.img-width {
  width: 1380px;
  margin: 0 atuo;
}
.profile-target {
  text-align: center;
  color: $color-text;

  .profile-target-connect {
    display: flex;

    height: 100%;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 60px;
      margin-bottom: 48px;
      span {
        color: $color-active;
      }
    }
    .profile-target-title {
      line-height: 36px;
      font-size: 22px;
      width: 1100px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 70px;
      font-weight: normal;
    }
    .profile-target-ul {
      display: flex;
      li {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        height: 640px;
        color: $color-text;
        overflow: hidden;
        position: relative;
        &:hover .profile-target-ul-location {
          opacity: 1;
        }
        &:hover .profile-target-ul-connect {
          opacity: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .profile-target-ul-connect {
          position: absolute;
          bottom: 0;
          left: 36px;
          right: 36px;
          height: 170px;
          opacity: 1;
          h4 {
            font-size: 20px;
            margin-bottom: 24px;
          }
          p {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .profile-target-ul-location {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          background: rgba(22, 22, 22, 0.8);
          padding-left: 36px;
          padding-right: 36px;
          h4 {
            font-size: 20px;
            margin-bottom: 24px;
          }
          p {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .banner-box {
    .banner-content-div {
      padding-bottom: 0;
      width: 86.4%;
      margin: 0 auto;
      .title-span {
        font-size: 0.42rem;
        margin-bottom: 0.36rem;
      }
      .desc-span {
        font-size: 0.24rem;
        line-height: 0.5rem;
      }
    }
  }

  .about-brief {
    height: auto;
    .about-brief-left {
      float: none;
      width: 100%;

      .about-brief-connect {
        padding-top: 0.91rem;
        width: 86.4%;
        margin: 0 auto;
        float: none;
        h2 {
          font-size: 0.42rem;
          margin-bottom: 0.36rem;
          text-align: center;
        }
        .about-brief-text {
          font-size: 0.24rem;
          line-height: 0.35rem;
          text-align: center;
        }
        ul.about-brief-column {
          padding-top: 0.36rem;
          text-align: center;
          li {
            font-size: 0.31rem;
            line-height: 0.65rem;
            .iconfont {
              font-size: 0.36rem;
            }
          }
        }
      }
    }
    .about-brief-video {
      float: none;
      width: 100%;
      height: 5.45rem;
      .about-brief-videoimg {
        line-height: 5.45rem;
        .iconfont {
          font-size: 0.58rem;
        }
      }
    }
  }

  .profile-mission {
    height: 7.82rem;
    .profile-mission-connect {
      width: 86.4%;
      .profile-mission-modular {
        height: 1.82rem;
        margin-bottom: 0.27rem;
        h2 {
          line-height: 1.82rem;
          font-size: 0.44rem;
        }
        .label_text {
          line-height: 1.82rem;
          font-size: 0.24rem;
        }
      }
    }
  }

  .public-spacing-tb {
    padding-top: 0;
    padding-bottom: 0.91rem;
  }
  .profile-target {
    .profile-target-connect {
      h2 {
        font-size: 0.42rem;
        margin-bottom: 0.36rem;
      }
      .profile-target-title {
        font-size: 0.24rem;
        line-height: 0.35rem;
        width: 86.4%;
        margin-bottom: 0.44rem;
      }
      .profile-target-ul {
        display: none;
      }
    }
  }

  .img-width,
  .businessmap {
    width: 86.4%;
  }

  .home-media-moile {
    display: block;
    // height: 8.29rem;
  }

  .right-divmap {
    width: 86%;
    .right-divmap-connect {
      color: #fff;
      img {
        height: 5.76rem;
      }
      .views {
        padding-top: 0.27rem;
        padding-left: 0.69rem;
        background: rgba(255, 255, 255, 0.05);
        padding-right: 0.69rem;
        padding-bottom: 0.45rem;
        text-align: center;
        h2 {
          font-size: 0.36rem;
          font-weight: normal;
          line-height: 130%;
          margin-bottom: 0.25rem;
        }
        h4 {
          font-size: 0.22rem;
          color: rgba(255, 255, 255, 0.7);
          padding-top: 0;
          margin-bottom: 0.36rem;
        }
        h6 {
          color: $color-active;
          font-size: 0.25rem;
        }
      }
    }
  }
  .programme-swiper {
    padding: 0;

    width: 100%;
    overflow: unset;
    .desc-p {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }

    .desc-paragraph {
      width: 86%;
      font-size: 0.27rem;
      line-height: 0.44rem;
      margin: 0.27rem auto;
    }
  }

  .home-media-moile {
    .home-pagination2 {
      position: absolute;
      bottom: -30px;
      text-align: center;
      top: auto;
      ::v-deep {
        .swiper-pagination-bullet {
          width: 24px;
          height: 3px;
          border-radius: 0;
          background-color: rgba($color: #fff, $alpha: 0.8);
          &.swiper-pagination-bullet-active {
            background-color: $color-active;
          }
        }
      }
    }
    .data-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    ::v-deep {
      .swiper-container {
        overflow: hidden !important;
        width: 86vw;
        height: auto;
      }
      .swiper-container .swiper-wrapper .swiper-slide {
        position: relative;
      }
      .swiper-container .swiper-wrapper .swiper-slide-prev {
        height: auto !important;
      }
      .swiper-container .swiper-wrapper .swiper-slide-prev img {
        height: 5.76rem !important;
      }
      .swiper-container .swiper-wrapper .swiper-slide-next {
        height: auto !important;
      }
      .swiper-container .swiper-wrapper .swiper-slide-next img {
        height: 5.76rem !important;
      }
      .swiper-container .swiper-wrapper .swiper-slide-active {
        .filter-div {
          // background-color: rgba($color: #161616, $alpha: 0);
          opacity: 0;
        }
      }
    }
  }
}
</style>
