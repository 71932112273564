<!--
 * @Author: your name
 * @Date: 2021-11-25 11:45:09
 * @LastEditTime: 2021-12-23 10:43:25
 * @LastEditors: Please set LastEditors
 * @Description: 解决方案 banner
 * @FilePath: \new-website-1123\src\views\programme\components\banner.vue
-->
<template>
  <div class="programme-banner">
    <img :src="url" alt="上太科技" />
    <div class="content-div">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.programme-banner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 750px) {
  .programme-banner {
    height: 7.27rem;
  }
}
</style>
