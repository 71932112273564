<!--
 * @Author: your name
 * @Date: 2021-11-25 10:24:16
 * @LastEditTime: 2022-02-15 14:56:37
 * @LastEditors: Please set LastEditors
 * @Description: 首页标题
 * @FilePath: \new-website-1123\src\views\Home\components\title.vue
-->
<template>
  <div class="home-title">
    <span>
      <slot></slot>
    </span>
    <img class="pc-img" :src="titleimg" v-if="titleimg" />
    <img class="wap-img" :src="stitleimg" v-if="stitleimg" />
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    titleimg: {
      type: [Array, String],
      required: true,
    },
    stitleimg: {
      type: [Array, String],
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-title {
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
}

.pc-img {
  display: block;
}
.wap-img {
  display: none;
}

@media screen and (min-width: 1500px) {
  .home-title {
    font-size: 80px;
  }
}
@media screen and (max-width: 1500px) {
  .home-title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 800px) {
  .home-title {
    font-size: 0.53rem;
  }

  .pc-img {
    display: none;
  }
  .wap-img {
    display: block;
    width: 3.89rem;
    margin: 0 auto;
  }
}
</style>
